"use client";
import { NAV_ITEM_TOOLTIP_DIRECTION } from "@/layouts/base-layout/navigation/main-section";
import { useAppStore } from "@/store";
import ToolTipper from "@llamaindex/component/ui/tooltipper";
import { Mail } from "lucide-react";
import Link from "next/link";
import React, { useCallback } from "react";

export const ContactSection: React.FC = () => {
  const isCollapsed = useAppStore((state) => state.isCollapsed);
  const openEmail = useCallback(() => {
    window.location.href = "mailto:contact@runllama.ai";
  }, []);
  if (isCollapsed) {
    return (
      <div className="mt-4 flex flex-col items-center gap-2">
        <ToolTipper content="Contact Us" side={NAV_ITEM_TOOLTIP_DIRECTION}>
          <Mail className="size-4" />
        </ToolTipper>
      </div>
    );
  }
  return (
    <div className="mt-8 text-xs font-normal">
      <div className="mb-2 text-[11px] font-normal uppercase tracking-wide text-slate-400">
        Contacts
      </div>
      <ul>
        <li>
          <Link
            href="#"
            onClick={openEmail}
            className="flex items-center rounded py-1"
          >
            Email Us
          </Link>
        </li>
        <li>
          <Link
            href="https://llamaindex.statuspage.io"
            className="flex items-center rounded py-1"
          >
            Status
          </Link>
        </li>
        {/* todo: use new pricing info */}
        <li>
          <Link
            href="https://docs.cloud.llamaindex.ai/llamaparse/usage_data"
            className="flex items-center rounded py-1"
          >
            Pricing info
          </Link>
        </li>
      </ul>
    </div>
  );
};
